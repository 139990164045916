import VanillaMoveable__default, { METHODS, PROPERTIES, EVENTS } from "moveable";
export * from "moveable";
import { defineComponent, openBlock, createElementBlock } from "vue";
import { isUndefined } from "@daybrush/utils";
const methods = {};
METHODS.forEach((name) => {
  methods[name] = function(...args) {
    return this.$_moveable[name](...args);
  };
});
const watch = {};
PROPERTIES.forEach((name) => {
  watch[name] = function(value) {
    this.$_moveable[name] = value;
  };
});
const VueMoveable = defineComponent({
  name: "moveable",
  methods,
  props: PROPERTIES,
  watch,
  mounted() {
    const options = {};
    const props = this.$props;
    PROPERTIES.forEach((name) => {
      const value = props[name];
      if (!isUndefined(value)) {
        options[name] = props[name];
      }
    });
    const refs = this.$refs;
    const moveableElement = refs.moveableElement;
    const moveable = new VanillaMoveable__default(moveableElement, {
      ...options,
      warpSelf: true
    });
    EVENTS.forEach((name) => {
      moveable.on(name, (e) => {
        this.$emit(name, { ...e });
      });
    });
    this.$_moveable = moveable;
  },
  beforeUnmount() {
    this.$_moveable.destroy();
  }
});
const _sfc_main = VueMoveable;
const _export_sfc = (sfc, props) => {
  const target = sfc.__vccOpts || sfc;
  for (const [key, val] of props) {
    target[key] = val;
  }
  return target;
};
const _hoisted_1 = { ref: "moveableElement" };
function _sfc_render(_ctx, _cache, $props, $setup, $data, $options) {
  return openBlock(), createElementBlock("div", _hoisted_1, null, 512);
}
const Moveable = /* @__PURE__ */ _export_sfc(_sfc_main, [["render", _sfc_render]]);
export {
  Moveable as VueMoveable,
  Moveable as default
};
