<template>
  <div class="container">
    <div :id=id class="test" :ref=id>Vue Moveable</div>
    <Moveable
        className="moveable"
        v-bind:target="['#' + this.id]"
        v-bind:draggable=options.draggable
        :edgeDraggable="true"
        v-bind:scalable=options.scalable
        v-bind:resizable=options.resizable
        v-bind:hideDefaultLines=true
        :edge="['w', 'e']"
        :contenteditable=true
        :OriginDraggable="true"
        @drag="onDrag"
        @resize="onResize"
    />
  </div>
</template>
<script>
import Moveable from "vue3-moveable";

export default {
  name: "MoveableDiv",
  props:{
    id: String,
    options: Object,
  },
  // eslint-disable-next-line no-unused-vars
  setup(props) {
    //console.log(props.options.resizable);
    // setup() receives props as the first argument.
    //document.body.style.background = "#321";
  },
  components: {
    Moveable,
  },
  methods: {
    onDrag( e ) {
      e.target.style.transform = e.transform;
    },
    onResize( e ) {
      e.target.style.width = `${e.width}px`;
      e.target.style.height = `${e.height}px`;
      e.target.style.transform = e.drag.transform;
    },
  }
};

export class options{
  constructor(obj){
    this.draggable = obj.draggable ?? false;
    this.resizable = obj.resizable ?? false;
    this.scalable = obj.scalable ?? false;
  }
}
</script>
<style scoped>
  .container{position:absolute;}
  .moveable-control.moveable-direction {
    opacity: 0!important;
  }
  .moveable-line {
    display: none!important;
  }
</style>