<template>
  <button @click="setTheme('light')">Light Theme</button>
  <button @click="setTheme('dark')">Dark Theme</button>
  <button @click="setTheme('space1')">Space1 Theme</button>
  <br><br>

  <button @click="serverStatus">Status</button>
  <br><br>
  <div>Test div here</div>
  <br>
<!--  <GG text="text_prop_custom_value"></GG>-->
  <button @click="setPath('GG')">GG Link</button>
  <br>
  <br>
  <button @click="setPath('Test')">Test Link</button>
  <br>
  <br>
  <button @click="setPath('orwgowrkg')">Broken Link</button>
  <br>
  <br>
  <br>
  <component :is="currentView" />
</template>

<script>
import SaveManager from "./libs/saveManager"
import { status } from "./libs/api/models/status.js";
import GG from "./components/GG.vue";
import TEST from "./components/TEST.vue";

import DefaultApi from "@/libs/api-v2/api/DefaultApi";

const routes = {
  'GG': GG,
  'Test': TEST,
};

export default {
  name: 'App',
  components: { GG, TEST },
  data() {
    return {
      currentPath: window.location.hash,
    };
  },
  computed: {
    currentView() {
      return routes[this.currentPath || '/'] || GG;
    }
  },
  methods: {
    setPath(path) {
      this.currentPath = path;
    },
    serverStatus() {
      fetch('https://api.spacetraders.io/v2/')
        .then((response) => response.json())
        .then((response) => console.log(new status(response)))
        .catch((err) => console.error(err));
      new DefaultApi().getStatus().then(status => {
        console.log(status);
      });
    },
    setTheme(theme) {
      document.documentElement.setAttribute('data-theme', theme);
      // document.body.setAttribute('data-theme', theme);
      SaveManager.save('theme', theme ?? "space1");
    },
  },

  beforeCreate(){
    //TODO Fetch from local storage
    let theme = SaveManager.read('theme') ?? "space1";
    // document.body.setAttribute('data-theme', theme);
    document.documentElement.setAttribute('data-theme', theme);

  },
};
</script>

<style>
  /*div {color: #a900af;}*/
</style>
